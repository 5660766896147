
#sidebar-menu  .form {

    .form-field {
        padding: 0;
    }

    div {
        background: transparent !important;
    }

    .mdc-form-field {
        color: $sidebarText;
        margin-left: -4px;
    }

    .mdc-form-field.checked {
        color: lighten($sidebarText, 50%);
    }

    .mdc-form-field:not(.checked) .mdc-checkbox__background {
        border-color: $sidebarText;
    }

    .mdc-checkbox {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    label {
        padding-top: 10px;
    }


}