@use 'sass:math';
@import "./src/styles/Library";
/* App */

/*
    Project customizations here
    Overides default dash styles
*/

#sidebar #sidebar-menu {
    padding-bottom: 8px;
}

#sidebar #sidebar-menu h5 {
    font-weight: 600;
    color: rgba($light0, .8);
}

#sidebar #sidebar-menu a {
    padding-top: 3px;
    padding-bottom: 3px;
}

#ribbon .zones  a.active {
    background: linear-gradient(90deg, rgba($sidebarBg, 0.05) 0%, rgba($sidebarBg, 0.15) 100%);
}

.page-tabs .mdc-tab {
    padding-left: 16px;
    padding-right: 16px;
}

.meta-table dt {
    font-weight: 700;
    color: $dark2;
}

.meta-table dd {
    padding-bottom: 4px;
    color: $dark0;
}

.form-field .form-field-label {
    font-weight: 700;
    color: $dark2;
    font-size: 13px;
}

.table-bg-gray {
    background-color: rgba($light1, .5) !important;
}

.table-bg-green {
    background-color: rgba($color: $greenHl, $alpha: .06) !important;
}

.table-bg-red {
    background-color: rgba($color: $redHl, $alpha: .06) !important;
}


.table-bdr-left-gray {
    border-left: 2px solid $light3 !important;
}

html .invoice-table {
    tbody .rmwc-data-table__cell {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }

    .invoice-breakdown-items {
        font-size: .9em;
    }

    .invoice-line-label {
        border-bottom-color: transparent !important;
    }

}

html .panel {
    box-shadow: none;
    outline: 3px solid rgba($light3, .2);
}