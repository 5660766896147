// Overwrite Variables Here

// $regular: "Aptos Narrow", sans-serif;

$primary: #FF5C04;
$secondary:#b2b9be;
$alt:#FF5C04;

$primaryLight: #fffae8;
$primaryDark: #1e1b14;

$sidebarBg: #29211e;
$ribbonBg: #fbc81d;

// $btnPrimaryText: $primaryDark;
// $btnHoverBg: $light0;
// $btnHoverBdr: $primary;
// $btnHoverText: $primaryDark;

$fieldBdr : $light3;
