@use 'sass:math';
@import "./src/styles/Library";
.stage-passed :global .panel-heading svg {
    color: $greenHl;
    margin-right: 4px;
    position: relative;
    top: 1px;
}

.stage-passed {
    outline-color: rgba($greenHl, .3) !important;
}
.stage-pending {
    outline-color: rgba($yellowHl, .3) !important;
}