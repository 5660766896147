/* Define color variables */
$statusYellow: #FFF38B;
$statusPink: #FF7DCA;
$statusPurple: #D899FC;
$statusLightOrange: #D2B29D;
$statusDarkOrange: #FFB155;
$statusGreen: #98F8AA;
$statusTeal: #3ccbb8;
$statusBlue: #77CBFF;
$statusGray: #CCCCCC;
$statusRed: #FF7276;
$statusWarn: #FF0000;

/* Installation STATUS */
.status {
    color: rgba($dark1, .8);
}

.status-new {
    background-color: rgba($statusYellow, .6);
}

.status-perso {
    background-color: rgba($statusPink, .6);
}

.status-quality_control {
    background-color: rgba($statusPurple, .6);
}

.status-outbound {
    background-color: rgba($statusLightOrange, .6);
}

.status-inbound {
    background-color: rgba($statusLightOrange, .6);
}

.status-testing {
    background-color: rgba($statusDarkOrange, .6);
}

.status-assign_technician {
    background-color: rgba($statusDarkOrange, .6);
}

.status-installation {
    background-color: rgba($statusDarkOrange, .6);
}

.status-complete {
    background-color: rgba($statusGreen, .6);
}

.status-complete_checked {
    background-color: rgba($statusGreen, .6);
}

.status-incomplete {
    background-color: rgba($statusWarn, .6);
}

.status-verified {
    background-color: rgba($statusTeal, .6);
}

.status-cancelled {
    background-color: rgba($statusGray, .6);
}

.status-pending {
    background-color: rgba($statusYellow, 0.6);
}

.status-in_progress {
    background-color: rgba($statusDarkOrange, 0.6);
}

/* Terminal STATUS */
.status-in_stock {
    background-color: rgba($statusTeal, .6);
}

.status-deployed {
    background-color: rgba($statusBlue, .6);
}

.status-delivered {
    background-color: rgba($statusBlue, .6);
}

.status-in_process {
    background-color: rgba($statusDarkOrange, .6);
}

.status-store {
    background-color: rgba($statusGreen, .6);
}

.status-store_used {
    background-color: rgba($statusTeal, .6);
}

.status-spare_stock {
    background-color: rgba($statusYellow, .6);
}

.status-technician {
    background-color: rgba($statusPink, .6);
}

.status-bench_repair {
    background-color: rgba($statusPurple, .6);
}

.status-acquirer_floating_unit {
    background-color: rgba($statusLightOrange, .6);
}

.status-warehouse_entry {
    background-color: rgba($statusDarkOrange, .6);
}

/* Merchant STATUS */
.status-new_merchant {
    background-color: rgba($statusYellow, .6);
}

.status-active {
    background-color: rgba($statusGreen, .6);
}

.status-installation_merchant {
    background-color: rgba($statusDarkOrange, .6);
}

.status-floating {
    background-color: rgba($statusTeal, .6);
}

.status-inactive {
    background-color: rgba($statusDarkOrange, .6);
}

.status-cancelled_merchant {
    background-color: rgba($statusGray, .6);
}

/* Helpdesk STATUS */
.status-helpdesk-new {
    background-color: rgba($statusYellow, .6);
}

.status-helpdesk-open {
    background-color: rgba($statusDarkOrange, .6);
}

.status-helpdesk-resolved {
    background-color: rgba($statusGreen, .6);
}

.status-helpdesk-unresolved {
    background-color: rgba($statusDarkOrange, .6);
}

.status-helpdesk-closed {
    background-color: rgba($statusGray, .6);
}

.status-helpdesk-normal {
    background-color: rgba($statusTeal, .6);
}

.status-helpdesk-high {
    background-color: rgba($statusPink, .6);
}

.status-helpdesk-urgent {
    background-color: rgba($statusPurple, .6);
}

// Service Type
.status-fault_call {
    background-color: rgba($statusTeal, .6);
}

.status-programming {
    background-color: rgba($statusPurple, .6);
}

.status-swap_plan {
    background-color: rgba($statusLightOrange, .6);
}

.status-retrieval {
    background-color: rgba($statusRed, .6);
}

.status-paper_roll {
    background-color: rgba($statusPink, .6);
}

.status-training {
    background-color: rgba($statusBlue, .6);
}

.status-yearly_rental {
    background-color: rgba($statusYellow, .6);
}

.status-amendment {
    background-color: rgba($statusGreen, .6);
}

.status-annual_visitation {
    background-color: rgba($statusDarkOrange, .6);
}

.status-approved {
    background-color: rgba($statusTeal, .6);
}

.status-paid {
    background-color: rgba($statusGreen, .6);
}

.status-refunded {
    background-color: rgba($statusGreen, .6);
}

.status-processing {
    background-color: rgba($statusGreen, .6);
}

.status-denied {
    background-color: rgba($statusRed, .6);
}

.status-pending_documents {
    background-color: rgba($statusRed, .6);
}

.status-claimed {
    background-color: rgba($statusTeal, .6);
}

.status-merged {
    background-color: rgba($statusPink, .6);
}